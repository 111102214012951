<template>
    <div id="top">
        <div class="img-home" id="img-medical">
            <div>
                <v-row >
                    <v-col cols="12" sm="9" class="text-home">
                        <h1 class="big-title">Vos paramètres santé avec l’implant BioTag Médical</h1>
                    </v-col>
                </v-row>
            </div>
        </div>
        <v-row>
            <v-col cols="12" sm="1" md="10" lg="2"></v-col>
            <v-col cols="12" sm="10" md="4" lg="4" class="mt50 pd5" >
                <div id="le-biotagsensor" class="trais"></div>
                <h1 class="green-title">L'implant Biotag</h1>
                <p class="text-classi"> <br> L'implant Biotag est un implant autonome, miniature et résistant, conçu pour mesurer avec une précision médicale les paramètres physiques et biologiques du patient. L’électronique intègre un système d’alerte capable de prévenir le porteur et plusieurs capteurs. Les mesures transmises sont la température, les accélérations X Y Z (activités et comportement) et le PPG ou photopléthysmographie  (rythme cardiaque, taux d'oxygène, tension artérielle). <br><br>
Les données mesurées sont stockées en temps réel sur un Cloud sécurisé. Les graphiques de toutes les données sont consultables via une application mobile ou PC, avec une courbe détaillée de la seconde jusqu'au mois. Les données recueillies permettent de mieux suivre l'état de santé des patients et donc de mieux comprendre l’évolution d’une maladie ou d’un traitement par exemple. L'application est facilement personnalisable. Elle possède une alerte paramétrable et programmable.
                </p>
                <div class="btn-loc">
                    <router-link to="/contact" id="btn" >Contactez-nous</router-link>
                </div>
                
            </v-col>
            <v-col sm="1" md="1" lg="1"></v-col>
            <v-col cols="12" sm="10" md="4" lg="4">
                <div class="img-implant">
                    <img src="../images/tagseul.png" alt="implant médical">
                </div>
            </v-col>
        </v-row>
        <implant-composant class="mt100"/>
        <v-row>
            <v-col cols="12" sm="1"></v-col>
            <v-col cols="12" sm="10" >
                <div class="trais-vr"></div>
                <h1 class="green-title mt50">Exemples d’applications </h1>
                <div class="applications">
                    <div>
                        <img src="../images/medecin_ordi.jpg" alt="medecin derrière son ordi">
                        <h3>Diagnostic précis </h3>
                        <p>Pour réaliser son diagnostic le plus précis, le professionnel de santé utilise les données  (temps réel, historique) transmises par l’implant.</p>
                    </div>
                    <div>
                        
                        <img src="../images/pansement.jpg" alt="application ciblée d'un pansement">
                        <h3>Traitement ciblé</h3>
                        <p>Pour adapter finement son traitement, le professionnel de santé compare l’évolution des données reçues par l’implant avant et pendant le traitement.</p>
                    </div>
                    <div>
                        <img src="../images/medecin_courbes.jpg" alt="travail">
                        <h3>Suivi continu de la santé</h3>
                        <p>Le professionnel de santé suit les mesures santé de son patient via l’application BioTagSensor et améliore ainsi sa qualité de vie</p>
                    </div>
                </div>
            </v-col>
        </v-row>
         <v-row >
            <v-col cols="12" sm="1" lg="2"></v-col>
            <v-col cols="12" sm="10" lg="4" class="mt50 pd5">
                <h1 class="green-title">Collaboration</h1>
                <p style="text-align: justify">Nos Implants donnent la possibilité d’intégrer de nouveaux capteurs ou solutions.
            Vous êtes porteur d’une solution ou avez un savoir-faire spécifique ? Travaillons ensemble à la réussite de votre projet implantable !
            </p>
            </v-col>
            <v-col cols="12" sm="10" lg="4" class="mt50 pd5">
                <div class="engrenages">
                    <img src="../images/poignée.jpg" alt="engrenages">
                </div>
            </v-col>
            <v-col cols="12" sm="1" lg="2"></v-col>
            
        </v-row>
        <foot/>
    </div>
</template>

<script>
import foot from '@/components/Footer.vue'
import ImplantComposant from '../components/ImplantComposant.vue'
export default {
    components:{
        foot,
        ImplantComposant
    },
}
</script>

<style scoped>

.img-home {
    background-size: cover;
    width: 100%;
    height: 100vh;
    background-repeat: no-repeat;
    text-align: left;
    position: relative;
  }

#img-medical{
    background-image: url(../images/medecin.jpg);
}

  .img-home::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* Ajout du filtre opaque noir */
    background-color: rgba(0, 55, 109, 0.33);
  }


.text-home {
    position: relative;
    top: 150px;
  }
.text-home h1{
    margin-left: 5%;
    width: 90%;
    margin-bottom: 5px;
}

.img-implant{
    margin-top: 170px;
}

.img-implant img{
    width: 100%;
    transform: rotate(100deg)
}

.fonctionnement{
    display: flex;
    justify-content: space-around;
}

.fonctionnement div{
    width: 23%; 
    text-align: left;
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
    padding-bottom: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    max-width: 100%; 
    overflow: hidden;
}

.fonctionnement div img{
    width: 100%;
    border: solid 1px var(--bleu);
    transition: transform 0.3s ease-in-out; /* ajouter une transition fluide */
}

.fonctionnement div img:hover{
    transform: scale(1.2); /* agrandir l'image de 20% */
}




.engrenages{
    width: 100%;
    overflow: hidden;
    height: 370px;
    border-radius: 20px;
}

.engrenages img{
    width: 100%;
}

.form{
    margin-left: 15%;
    margin-bottom: 50px;
    margin-top: 50px;
    width: 70%;
}

.form input{
    width: 55%;
    border: solid 1px var(--violet);
    background-color: transparent;
    height: 35px;
    margin-right: 5%;
}

.p-collaboration{
    font-family: 'Roboto',  sans-serif;
    font-size: 1.3rem;
}

@media (max-width: 1264px) {
.data-mesurées{
    margin-left: 5%;
    width: 90%;
}

.avantages{
    margin-left: 5%;
    width: 90%;
}
.applications div{
    width: 45%;
}
}



@media (max-width: 1264px) {
.img-medical{
    margin-top: 130px;
    height: 80vh;
}

.img-medical img{
    width: 100%;
}

.fonctionnement{
    display: block;
}

.fonctionnement div{
    width: 100%;
}

.applications{
    display: block;
}

.applications div{
    width: 100%;
}

.text-home{
    text-align: justify;
    margin-top: 50px;
}


.text-home h1{
    width: 100%;
    margin-bottom: 10px;
}


.offres div{
    width: 45%;
    margin-left: 5.5%;
}

.img-implant{
    margin-top: 50px;
}

.img-implant img{
    margin-left: 5%;
    width: 90%;
    
}

.p-collaboration{
    font-family: 'Roboto',  sans-serif;
    font-size: 1rem;
}
}

@media (max-width: 765px) {
.img-doigt img{
    width: 100%;
}
.offres{
    display: block;
}

.offres div{
    width: 90%;
}
}
</style>

